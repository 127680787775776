<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>
        {{ auditReport.Name }}
      </h1>
      <div class="btns-container">
        <More
          :disableButtons="[false, false, false, false]"
          :hideButtons="[false, false, false, false]"
          @shareClicked="generatePdf()"
          @editClicked="editReportClick()"
          @scheduledClicked="scheduleReport()"
          @deleteClicked="deleteReport()"
        />
        <VasionButton id="run-report-button" :classProp="'primary'" @vasionButtonClicked="generateReport()">
          Run Report
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="auditReportTableData.tableHeaders">
        <div v-if="auditReportTableData.tableRows.length > 0" id="no-results-div" class="save-audit-report-table-container">
          <VasionTable id="report-table" :headerColumns="auditReportTableData.tableHeaders" :tableRows="auditReportTableData.tableRows" />
        </div>
        <div v-else>
          No data for the given report configuration
        </div>
      </div>
      <div v-else class="center spacer">
        <VasionEmptyReportsIcon />
        <div class="vasion-large-message">
          Run a Report to view entries
        </div>
      </div>
    </div>
    <md-dialog id="confirmLeave" :md-active.sync="showDeleteDialog" @md-clicked-outside="cancelDelete()">
      <VasionConfirmationDialog
        message="Are you sure you want to delete this report?"
        @noButtonClick="cancelDelete()"
        @yesButtonClick="doDelete()"
      />
    </md-dialog>
    <VasionSnackbar
      id="delete-folder-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import More from '@/components/reporting/More.vue'

export default {
  name: 'TheSavedAuditReport',
  components: {
    Loading,
    More,
  },
  data: function () {
    return {
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarTitle: '',
      snackbarSubTitle: '',
      snackbarImage: '',
      auditReport: {},
    }
  },
  computed: {
    auditReportTableData() { return this.$store.state.reporting.auditReport },
  },
  watch: {
    auditReport: function () {
      this.$store.dispatch('reporting/resetReports')
    },
  },
  async created() {
    this.auditReport = await this.$store.dispatch('reporting/getSavedAuditReport', this.$route.params.reportId)
  },
  methods: {
    cancelDelete() {
      this.showDeleteDialog = !this.showDeleteDialog
    },
    deleteReport() {
      this.showDeleteDialog = !this.showDeleteDialog
    },
    async doDelete() {
      const response = await this.$store.dispatch('reporting/deleteAuditReport', this.auditReport.AuditReportID)
      if (response.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarImage = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Report has been deleted'

        await this.$store.dispatch('reporting/loadReports')
        this.$router.push({ name: 'TheEmptyReport' })
      }
    },
    async editReportClick() {
      this.$store.dispatch('reporting/setEditingAuditReport', true)
      await this.$store.dispatch('reporting/setAuditReportData', this.auditReport)
      this.$router.push({ name: 'TheAuditReport' })
    },
    generateReport: async function () {
      this.isLoading = true

      const payload = {
        UserID: this.auditReport.UserID,
        ActionID: this.auditReport.ActionID,
        DateFrom: this.auditReport.DateFrom,
        DateTo: this.auditReport.DateTo,
        Memo: this.auditReport.Memo,
        AndSearch: this.auditReport.AndSearch,
      }
      await this.$store.dispatch('reporting/runAuditReport', payload)

      this.isLoading = false
    },
    async generatePdf() {
      this.isLoading = true
      const payload = {
        UserID: this.auditReport.UserID,
        ActionID: this.auditReport.ActionID,
        DateFrom: this.auditReport.DateFrom,
        DateTo: this.auditReport.DateTo,
        Memo: this.auditReport.Memo,
        AndSearch: this.auditReport.AndSearch,
      }
      const response = await this.$store.dispatch('reporting/generateAuditReportPDF', payload)
      if (!response) {
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There may be no data to share.'
        this.showSnackbarBool = true
      }
      await this.$amplitude.trackEvent('Report Shared', {
        'reportType': 'Audit',
      })
      this.isLoading = false
    },
    async scheduleReport() {
      await this.$store.dispatch('reporting/setScheduledReportName', this.auditReport.Name)
      this.$router.push({ name: 'EditReportScheduledReporting' })
    },
  },
}
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
}

.buttons {
  flex-wrap: wrap;
}

.spacer {
  margin-top: 75px;
}
.save-audit-report-table-container {
  height: calc(100vh - 280px);
}
</style>
